import { useDeepCompareEffect } from 'ahooks';
import { Form, FormInstance } from 'antd';
import { NamePath, Store } from 'rc-field-form/es/interface';
import { useMemo } from 'react';

import { useSettings } from '../SettingsProvider';

import EnhancedForm from './index';

const useLocalWatch = <ValueType = Store>(
  name: NamePath,
  form: FormInstance,
  defaultValue: ValueType,
) => {
  const state = EnhancedForm.useEnhancedFormState();
  const formValue = Form.useWatch<ValueType>(name, form);

  if (!state.name) {
    throw Error('Form name is required for useLocalWatch hook');
  }

  const [value = defaultValue, setValue] = useSettings<ValueType | undefined>(
    [state.name, ...(Array.isArray(name) ? name : [name])].join('-'),
  );

  useDeepCompareEffect(() => {
    if (Array.isArray(formValue) ? formValue.length : formValue) {
      setValue(formValue);
    }
  }, [value, formValue, setValue]);

  return useMemo(() => value, [value]);
};

export default useLocalWatch;
