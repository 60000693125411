import { Form, FormItemProps } from 'antd';
import { NamePath } from 'rc-field-form/es/interface';
import { FC } from 'react';

import SyncedEnhancedFormItem from '../SyncedItem';

type EnhancedFormItemProps = Omit<FormItemProps, 'name'> & {
  name: NamePath;
  sync?: boolean;
};
const EnhancedFormItem: FC<EnhancedFormItemProps> = ({ sync, ...rest }) => {
  if (sync) {
    return <SyncedEnhancedFormItem {...rest} />;
  }

  return <Form.Item {...rest} />;
};

EnhancedFormItem.displayName = 'EnhancedFormItem';

export default EnhancedFormItem;
