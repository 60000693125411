import { ReloadOutlined } from '@ant-design/icons';
import { FallbackRender } from '@sentry/react';
import { Button, Result } from 'antd';

import ChunkLoadError from '../../routes/Home/ChunkLoadError';
import HeadTitle from '../HeadTitle';

const title = 'Error';

const ErrorBoundaryFallback: FallbackRender = (errorData) => {
  const { error } = errorData;

  const isChunkLoadError = error?.toString()?.includes('ChunkLoadError');

  if (isChunkLoadError) {
    return <ChunkLoadError />;
  }

  return (
    <>
      <HeadTitle>{title}</HeadTitle>
      <Result
        extra={
          <Button
            href={window.location.href.split('?')[0]}
            icon={<ReloadOutlined />}
            type="primary"
          >
            Reload
          </Button>
        }
        status={500}
        subTitle={error?.toString()}
        title={title}
      />
    </>
  );
};

export default ErrorBoundaryFallback;
