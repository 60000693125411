import { Button, ButtonProps } from 'antd';
import { FC, useCallback } from 'react';

import { useCookieSettings } from '../../../CookieSettingsProvider';

const DeclineAllButton: FC<ButtonProps> = ({ onClick, ...rest }) => {
  const settings = useCookieSettings();
  const onDeclineAll = useCallback(() => {
    settings?.forEach(([, , setValue]) => setValue?.(false));
  }, [settings]);

  return (
    <Button
      onClick={(e) => {
        onDeclineAll();
        onClick?.(e);
      }}
      {...rest}
    >
      Decline All
    </Button>
  );
};

DeclineAllButton.displayName = 'DeclineAllButton';

export default DeclineAllButton;
