import { Button, ButtonProps } from 'antd';
import { FC, useCallback } from 'react';

import { useCookieSettings } from '../../../CookieSettingsProvider';

const AcceptAllButton: FC<ButtonProps> = ({ onClick, ...rest }) => {
  const settings = useCookieSettings();
  const onAcceptAll = useCallback(() => {
    settings?.forEach(([, , setValue]) => setValue?.(true));
  }, [settings]);

  return (
    <Button
      onClick={(e) => {
        onAcceptAll();
        onClick?.(e);
      }}
      {...rest}
    >
      Accept All
    </Button>
  );
};

AcceptAllButton.displayName = 'AcceptAllButton';

export default AcceptAllButton;
