import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

type NamespaceProps = {
  namespace: string;
};

export const NamespaceContext = createContext<string>('');

export const useNamespace = (): string => useContext(NamespaceContext);

const NamespaceProvider: FC<PropsWithChildren<NamespaceProps>> = ({
  children,
  namespace,
}) => {
  const parent = useNamespace();
  const value = useMemo(
    () => (parent ? `${parent}-${namespace}` : namespace),
    [namespace, parent],
  );

  return (
    <NamespaceContext.Provider value={value}>
      {children}
    </NamespaceContext.Provider>
  );
};

NamespaceProvider.displayName = 'NamespaceProvider';

export default NamespaceProvider;
