import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useConfig } from '../ConfigProvider';
import { useCookieSetting } from '../CookieSettingsProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.dataLayer = window.dataLayer || [];

function gtag() {
  // analytics wants exactly arguments not args
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  window.dataLayer.push(arguments);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
gtag('js', new Date());

let appended = false;

const GoogleAnalytics = () => {
  const [consent] = useCookieSetting('cookie.performance');
  const { analyticsId: trackingId } = useConfig();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!appended && trackingId && consent) {
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

      document.body.appendChild(script);

      appended = true;
    }
  }, [consent, trackingId]);

  useEffect(() => {
    if (trackingId && consent) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gtag('config', trackingId, { page_path: pathname });
    }
  }, [trackingId, pathname, consent]);

  return null;
};

GoogleAnalytics.displayName = 'GoogleAnalytics';

export default GoogleAnalytics;
