import { G2 } from '@ant-design/charts';
import { useMount } from 'ahooks';
import { ConfigProvider, message } from 'antd';
import en from 'antd/es/locale/en_US';
import moment from 'moment';
import { FC, PropsWithChildren } from 'react';

// https://ant.design/components/date-picker/#How-to-modify-start-day-of-week
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export const maxMessages = 5;

message.config({
  maxCount: maxMessages,
});

const locale = {
  ...en,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Pagination: {
    ...en.Pagination,
    jump_to: 'Page #',
  },
};

const form = {
  validateMessages: {
    required: 'Required',
  },
};

const pageHeader = {
  ghost: false,
};

const AntConfigProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { registerTheme } = G2;

  useMount(() => {
    registerTheme('custom', {
      colors10: [
        '#1890FF',
        '#41D9C7',
        '#2FC25B',
        '#FACC14',
        '#E6965C',
        '#223273',
        '#7564CC',
        '#8543E0',
        '#5C8EE6',
        '#007E99',
      ],
      colors20: [
        '#1890FF',
        '#41D9C7',
        '#2FC25B',
        '#FACC14',
        '#E6965C',
        '#223273',
        '#7564CC',
        '#8543E0',
        '#5C8EE6',
        '#007E99',
        '#FF745A',
        '#FFA8A8',
        '#2391FF',
        '#FFC328',
        '#A0DC2C',
        '#946DFF',
        '#626681',
        '#EB4185',
        '#CD8150',
        '#327039',
      ],
    });
  });

  return (
    <ConfigProvider
      form={form}
      getPopupContainer={(node) => {
        // TODO: Make sure that dropdowns in modals stick to modal scroll container
        const modals = document.getElementsByClassName('ant-modal-content');
        if (modals.length) {
          return node || document.body;
        }

        const getContainer = (node?: HTMLElement): HTMLElement => {
          if (node?.attributes?.getNamedItem('data-popup-container')) {
            return node;
          }
          if (node?.parentNode) {
            return getContainer(node?.parentNode as HTMLElement);
          }

          return document.body;
        };

        return getContainer(node);
      }}
      locale={locale}
      pageHeader={pageHeader}
    >
      {children}
    </ConfigProvider>
  );
};

AntConfigProvider.displayName = 'AntConfigProvider';

export default AntConfigProvider;
