import { Switch, SwitchProps } from 'antd';
import { FC } from 'react';

import { useCookieSetting } from '../../CookieSettingsProvider';

type CookieSwitchProps = SwitchProps & {
  name: string;
};

const CookieSwitch: FC<CookieSwitchProps> = ({ name, ...rest }) => {
  const [value, setValue] = useCookieSetting(name) || [];

  return <Switch checked={value} onChange={setValue} {...rest} />;
};

CookieSwitch.displayName = 'CookieSwitch';

export default CookieSwitch;
