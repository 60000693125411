// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';

import { useConfig } from '../ConfigProvider';
import { useCookieSetting } from '../CookieSettingsProvider';

const Hotjar = () => {
  const [consent] = useCookieSetting('cookie.functionality');
  const { hotjarId } = useConfig();

  useEffect(() => {
    if (hotjarId && consent) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,prefer-rest-params,@typescript-eslint/no-unsafe-member-access
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: hotjarId, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-member-access
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      /* eslint-enable */
    }
  }, [consent, hotjarId]);

  return null;
};

Hotjar.displayName = 'Hotjar';

export default Hotjar;
