import { useDeepCompareEffect } from 'ahooks';
import { Form, FormInstance, FormProps, Spin } from 'antd';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

import Item from './Item';
import SyncedItem from './SyncedItem';
import useLocalWatch from './useLocalWatch';

export type EnhancedFormProps<T> = Omit<
  FormProps<T>,
  'initialValues' | 'children'
> & {
  resetOnInitialize?: boolean;
  initialValues?: Partial<T>;
  loading?: boolean;
  children?: ((values: T, form: FormInstance) => JSX.Element) | ReactNode;
};

export type EnhancedFormStateProps = {
  dirty: boolean;
  name?: string;
  pristine: boolean;
};

export const EnhancedFormStateContext = createContext<EnhancedFormStateProps>({
  dirty: false,
  name: undefined,
  pristine: true,
});

const useEnhancedFormState = () => {
  return useContext(EnhancedFormStateContext);
};

const EnhancedForm = <T extends object>({
  form: formProp,
  name,
  loading = false,
  initialValues,
  children,
  ...rest
}: EnhancedFormProps<T>): ReactElement => {
  const [pristine, setPristine] = useState(true);
  const [formInstance] = Form.useForm();

  const form = useMemo(
    () => formProp || formInstance,
    [formInstance, formProp],
  );

  const values = Form.useWatch<T>([], form);

  // https://github.com/ant-design/ant-design/issues/22372#issuecomment-602102164
  useDeepCompareEffect(() => {
    if (initialValues && !loading) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form.setFieldsValue(initialValues);
      form.resetFields();
      setPristine(true);
    }
  }, [form, initialValues, loading]);

  return (
    <Spin spinning={loading}>
      <EnhancedFormStateContext.Provider
        value={{
          dirty: !pristine,
          name,
          pristine,
        }}
      >
        <Form<T>
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          name={name}
          onSubmitCapture={() => {
            setPristine(true);
          }}
          onValuesChange={() => {
            setPristine(false);
          }}
          scrollToFirstError
          {...rest}
        >
          {typeof children === 'function' ? children(values, form) : children}
        </Form>
      </EnhancedFormStateContext.Provider>
    </Spin>
  );
};

EnhancedForm.useEnhancedFormState = useEnhancedFormState;
EnhancedForm.useLocalWatch = useLocalWatch;
EnhancedForm.Item = Item;
EnhancedForm.SyncedItem = SyncedItem;

EnhancedForm.displayName = 'EnhancedForm';

export default EnhancedForm;
