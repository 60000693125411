import { useTitle } from 'ahooks';
import { Button, Result } from 'antd';
import type { ResultProps } from 'antd/es/result';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import routes from '../../paths.json';

const messages = {
  [403]: 'The request was valid, but the server is refusing action.',
  [404]:
    "The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible.'",
  [500]:
    'A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.',
};

type ExceptionProps = ResultProps & {
  actions?: ReactNode | ReactNode[];
  desc?: ReactNode;
  type?: 403 | 404 | 500;
};

const Exception: FC<ExceptionProps> = ({
  actions,
  desc: descProp,
  title: titleProp,
  type = 404,
  ...rest
}) => {
  const title = String(titleProp || type);
  useTitle(title);
  const desc = descProp || (type && messages[type]);

  return (
    <Result
      extra={
        actions || (
          <Link to={routes.home}>
            <Button type="primary">Back to Homepage</Button>
          </Link>
        )
      }
      status={type}
      subTitle={desc}
      title={title}
      {...rest}
    />
  );
};

Exception.displayName = 'Exception';

export default Exception;
