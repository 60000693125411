import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { useEffect, useMemo } from 'react';

import { useAuthentication } from '../../AuthenticationProvider';
import { useConfig } from '../../ConfigProvider';
import authLink, { setAuthorization } from '../authLink';
import omitTypeNameLink from '../omitTypeNameLink';
import sentryLink from '../sentryLink';
import useErrorLink from '../useErrorLink';

import typePolicies from './typePolicies';

const useClient = () => {
  const { accessToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      setAuthorization(accessToken);
    }
  }, [accessToken]);

  const cache = useMemo(() => new InMemoryCache({ typePolicies }), []);

  const errorLink = useErrorLink();
  const { datagonUrl } = useConfig();

  return useMemo(() => {
    const uploadLink = createUploadLink({ uri: datagonUrl });

    const link = authLink.concat(
      ApolloLink.from([omitTypeNameLink, errorLink, sentryLink, uploadLink]),
    );

    return new ApolloClient({
      cache,
      connectToDevTools: true,
      defaultOptions: {
        query: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
      },
      link,
      queryDeduplication: false,
    });
  }, [cache, datagonUrl, errorLink]);
};

export default useClient;
