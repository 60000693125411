import { ThunderboltOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Space } from 'antd';
import { NamePath } from 'rc-field-form/es/interface';
import { createContext, FC, useContext } from 'react';

import EnhancedForm from '../index';

type SyncedEnhancedFormItemProps = Omit<FormItemProps, 'name'> & {
  name: NamePath;
};

export const SyncedEnhancedFormItemContext = createContext<
  SyncedEnhancedFormItemProps | undefined
>(undefined);

export const useFormItemState = () => {
  return useContext(SyncedEnhancedFormItemContext);
};

const SyncedEnhancedFormItem: FC<SyncedEnhancedFormItemProps> = ({
  name,
  initialValue,
  label,
  ...rest
}) => {
  const form = Form.useFormInstance();

  const value = EnhancedForm.useLocalWatch(name, form, initialValue);

  return (
    <SyncedEnhancedFormItemContext.Provider
      value={{ initialValue, label, name, ...rest }}
    >
      <Form.Item
        initialValue={value}
        label={
          <Space align="center" size={4}>
            {label}
            <ThunderboltOutlined style={{ fontSize: 10 }} />
          </Space>
        }
        name={name}
        {...rest}
      />
    </SyncedEnhancedFormItemContext.Provider>
  );
};

SyncedEnhancedFormItem.displayName = 'SyncedEnhancedFormItem';

export default SyncedEnhancedFormItem;
