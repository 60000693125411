import { useLocalStorageState } from 'ahooks';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import { useSearchQuery } from '../../utils/location';
import CookieNotification from '../CookieNotification';

import _cookies from './cookies.json';

export type Cookie = {
  name: string;
  provider: string;
  expiration: string;
  description: string;
};

export type CookieCategory = {
  title: string;
  name: string;
  description: string;
  cookies: Cookie[];
};

type Value = boolean | undefined;
type SetValue = (prevState?: Value) => Value;

export type CookieSetting = [string, Value, SetValue];

const cookies = _cookies as CookieCategory[];

export const CookieSettingsContext = createContext<CookieSetting[] | undefined>(
  undefined,
);

export const useCookieSetting = (key: string): [Value, SetValue] => {
  return (
    useContext(CookieSettingsContext)?.find(([name]) => name === key) || []
  ).slice(1) as [Value, SetValue];
};

export const useCookieSettings = (): CookieSetting[] | undefined => {
  return useContext(CookieSettingsContext);
};

const CookieSettingsProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { searchQuery } = useSearchQuery<{ noCookies?: boolean }>();
  const deserializer = useCallback(
    (value?: string) => (value ? value === 'true' : value),
    [],
  );

  const settings = cookies.map(({ name }) => [
    name,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ...useLocalStorageState(name, {
      deserializer,
    }),
  ]) as CookieSetting[];

  return (
    <CookieSettingsContext.Provider
      value={searchQuery.noCookies ? undefined : settings}
    >
      {children}
      <CookieNotification />
    </CookieSettingsContext.Provider>
  );
};

CookieSettingsProvider.displayName = 'CookieSettingsProvider';

export default CookieSettingsProvider;
