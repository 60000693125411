import { useDeepMemo } from '@apollo/client/react/hooks/internal';
import { useLocalStorageState } from 'ahooks';
import { SetState } from 'ahooks/es/createUseStorageState';
import { get, set } from 'lodash/fp';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type Key = string | number | (string | number)[];

type Settings = Record<string, unknown> | undefined;

type SetSettings<T = Settings> = (value?: SetState<T>) => void;

const SettingsContext = createContext<readonly [Settings, SetSettings]>([
  {},
  () => {
    throw new Error('No <SettingsContext />');
  },
]);

export const useSettings = <T,>(
  key: Key,
): [T | undefined, (value?: T) => void] => {
  const [localSettings, setLocalSettings] = useContext(SettingsContext);

  const settings = useMemo(
    () => get(key)(localSettings) as T | undefined,
    [key, localSettings],
  );

  const setSettings = useCallback(
    (value?: T) => {
      setLocalSettings((state) => {
        return set(key, value)(state || {}) as Settings;
      });
    },
    [key, setLocalSettings],
  );

  return useMemo(() => [settings, setSettings], [setSettings, settings]);
};
const SettingsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [settings, setSettings] = useLocalStorageState<Settings>('settings');

  const value = useDeepMemo(
    () => [settings, setSettings],
    [settings, setSettings],
  ) as [Settings, SetSettings];

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.displayName = 'SettingsProvider';

export default SettingsProvider;
