// https://ant.design/components/grid/#Col

enum Breakpoint {
  SM_MIN = 576,
  MD_MIN = 768,
  LG_MIN = 992,
  XL_MIN = 1200,
  XXL_MIN = 1600,
}

export default Breakpoint;
