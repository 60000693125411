import { notification } from 'antd';
import type { ArgsProps } from 'antd/es/notification';

const showErrorNotification = (config: ArgsProps): void =>
  notification.error({
    duration: 60,
    ...config,
  });

export default showErrorNotification;
