import { Spin } from 'antd';
import type { SpinProps } from 'antd/es/spin';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './styles.module.less';

const Loading: FC<SpinProps> = ({ className, spinning, ...rest }) => (
  <Spin
    className={classNames(styles.spin, className)}
    data-name="loading"
    data-state={spinning}
    size="large"
    spinning={spinning}
    {...rest}
  />
);

export default Loading;
