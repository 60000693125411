import { useLocalStorageState } from 'ahooks';
import { Alert, Button, Space } from 'antd';
import { FC } from 'react';

import { useVersionQuery } from './Version.generated';

const isGreater = (a: string, b: string) => {
  return a.localeCompare(b, undefined, { numeric: true }) === 1;
};

const VersionAlert: FC = () => {
  const { data } = useVersionQuery({
    pollInterval: 600000,
  });
  const [version, setVersion] = useLocalStorageState<string>('version', {
    defaultValue: VERSION,
  });

  const appVersion = data?.serviceVersions?.app || version;

  const onUpgrade = () => {
    setVersion(appVersion);
  };

  const onClose = () => {
    setVersion(VERSION);
  };

  if (version && isGreater(version, VERSION)) {
    return (
      <Alert
        action={
          <Space>
            <Button
              href={window.location.href}
              onClick={onUpgrade}
              size="small"
              type="ghost"
            >
              Retry
            </Button>
            <Button
              ghost
              href="mailto: support@swaarm.com"
              size="small"
              type="primary"
            >
              Get Support
            </Button>
          </Space>
        }
        banner
        message="There was a problem upgrading to a new version of Swaarm. Please clear your browser cache."
        onClose={onClose}
        type="error"
      />
    );
  }

  if (appVersion && isGreater(appVersion, VERSION)) {
    return (
      <Alert
        action={
          <Button
            ghost
            href={window.location.href}
            onClick={onUpgrade}
            size="small"
            type="primary"
          >
            Get New Version
          </Button>
        }
        banner
        message="A new version of Swaarm is waiting for you. Please reload the page to avail new features."
        type="info"
      />
    );
  }

  return null;
};

VersionAlert.displayName = 'VersionAlert';

export default VersionAlert;
