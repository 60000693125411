import { setContext } from '@apollo/client/link/context';

let authorization: string;
let organizationId: number;

export const setAuthorization = (value: string): void => {
  authorization = `Bearer ${value}`;
};

export const setOrganization = (value: number): void => {
  organizationId = value;
};

const authLink = setContext((_, { headers }) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  headers: {
    ...headers,
    authorization,
    'X-auth-origin': organizationId,
  },
}));

export default authLink;
