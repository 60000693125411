import { SaveOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCookieSettings } from '../CookieSettingsProvider';
import cookies from '../CookieSettingsProvider/cookies.json';
import EnhancedForm from '../EnhancedForm';

import CookieConsentModal from './CookieConsentModal';
import styles from './styles.module.less';

const CookieNotification = () => {
  const settings = useCookieSettings();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [pristine, setPristine] = useState(true);

  const configured = useMemo(
    () =>
      !settings || settings?.some(([, value]) => typeof value === 'boolean'),
    [settings],
  );

  const onFinish = useCallback(
    (values: Record<string, boolean>) => {
      settings?.forEach(([key, , setValue]) => {
        setValue?.(values[key]);
      });
    },
    [settings],
  );

  const description = useMemo(
    () => (
      <Space direction="vertical" size={12}>
        <div>
          We use cookies to improve user experience. Choose what cookies you
          allow us to use. You can read more about our Cookie Policy in our{' '}
          <Typography.Link
            href="https://swaarm.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Typography.Link>
          .
        </div>
        <EnhancedForm
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={() => setPristine(false)}
        >
          <Space direction="vertical" size={16}>
            <Row gutter={[8, 8]}>
              {cookies.map(({ name, title }) => (
                <Col key={name} xs={24}>
                  <Form.Item name={name} noStyle valuePropName="checked">
                    <Checkbox>{title}</Checkbox>
                  </Form.Item>
                </Col>
              ))}
              <Col xs={24}>
                <Button
                  icon={<SettingOutlined />}
                  onClick={() => {
                    notification.destroy();
                    setOpen(true);
                  }}
                  size="small"
                  style={{ padding: '0px 1px' }}
                  type="link"
                >
                  Show Details
                </Button>
              </Col>
            </Row>
            <Row align="middle" gutter={[16, 8]} justify="center">
              <Col xs={12}>
                {!pristine ? (
                  <Button
                    block
                    icon={<SaveOutlined />}
                    onClick={() => {
                      form.submit();
                      notification.destroy();
                    }}
                    type="primary"
                  >
                    Save & Close
                  </Button>
                ) : (
                  <Button
                    block
                    onClick={() => {
                      form.setFieldsValue(
                        cookies.reduce((result, { name }) => {
                          return {
                            ...result,
                            [name]: true,
                          };
                        }, {}),
                      );
                      form.submit();
                      notification.destroy();
                    }}
                    type="primary"
                  >
                    Accept All
                  </Button>
                )}
              </Col>
              <Col xs={12}>
                <Button
                  block
                  onClick={() => {
                    form.setFieldsValue(
                      cookies.reduce((result, { name }) => {
                        return {
                          ...result,
                          [name]: false,
                        };
                      }, {}),
                    );
                    form.submit();
                    notification.destroy();
                  }}
                >
                  Decline All
                </Button>
              </Col>
            </Row>
          </Space>
        </EnhancedForm>
      </Space>
    ),
    [form, onFinish, pristine],
  );

  useEffect(() => {
    if (!configured && !open) {
      notification.open({
        className: styles.notification,
        description,
        duration: null,
        key: 'cookie-consent',
        message: (
          <Typography.Text strong>This website uses cookies</Typography.Text>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [configured, description, open]);

  return (
    <CookieConsentModal
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      open={open}
    />
  );
};

CookieNotification.displayName = 'CookieNotification';

export default CookieNotification;
