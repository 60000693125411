import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC, useMemo, useState } from 'react';

import DataTable, { DataTableProps } from '../../DataTable';

type Cookie = {
  name: string;
  provider: string;
  expiration: string;
  description: string;
};

const CookieTable: FC<DataTableProps<Cookie>> = (props) => {
  const [open, setOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        title: 'Name',
        width: 180,
      },
      {
        dataIndex: 'provider',
        title: 'Provider',
        width: 100,
      },
      {
        dataIndex: 'expiration',
        title: 'Expiration',
        width: 120,
      },
      {
        dataIndex: 'description',
        title: 'Description',
      },
    ],
    [],
  );

  return (
    <>
      {open && (
        <DataTable
          bordered={false}
          columns={columns}
          pagination={{
            hideOnSinglePage: true,
          }}
          style={{ marginBottom: 8 }}
          {...props}
        />
      )}
      <Button
        icon={open ? <CaretUpOutlined /> : <CaretDownOutlined />}
        onClick={() => setOpen(!open)}
        size="small"
        style={{ padding: 0 }}
        type="link"
      >
        {open ? 'Hide Cookies' : 'Show Cookies'}
      </Button>
    </>
  );
};

CookieTable.displayName = 'CookieTable';

export default CookieTable;
