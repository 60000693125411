import { ReloadOutlined, SmileTwoTone } from '@ant-design/icons';
import { Button, Result, ResultProps } from 'antd';
import { FC } from 'react';

const ChunkLoadError: FC<ResultProps> = (props) => {
  return (
    <Result
      extra={
        <Button
          href={window.location.href}
          icon={<ReloadOutlined />}
          type="primary"
        >
          Reload
        </Button>
      }
      icon={<SmileTwoTone />}
      title={
        <>
          A new version of Swaarm is waiting for you.
          <br />
          Please reload the page to avail new features.
        </>
      }
      {...props}
    />
  );
};

ChunkLoadError.displayName = 'ChunkLoadError';

export default ChunkLoadError;
