import { useTitle } from 'ahooks';
import { FC } from 'react';

const HeadTitle: FC<{ children: string }> = ({ children }) => {
  useTitle(children);

  return null;
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
