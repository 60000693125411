import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Modal, ModalProps, Row, Space, Typography } from 'antd';
import { FC, useCallback } from 'react';

import Breakpoint from '../../../constants/Breakpoint';
import CookieList from '../../CookieList';
import cookies from '../../CookieSettingsProvider/cookies.json';

import AcceptAllButton from './AcceptAllButton';
import DeclineAllButton from './DeclineAllButton';
import styles from './styles.module.less';

type CookieConsentModalProps = ModalProps & {
  onClose?: () => void;
};

const CookieConsentModal: FC<CookieConsentModalProps> = ({
  onClose,
  ...rest
}) => {
  const onSave = useCallback(() => {
    cookies
      .map(({ name }) => [name, localStorage.getItem(name)])
      .map(([name, value]) => {
        if (value === 'true') {
          return [name, true];
        }

        if (value === 'false') {
          return [name, false];
        }

        return [name, undefined];
      })
      .map(([key, value]) => {
        if (value === undefined) {
          localStorage.setItem(key as string, String(false));
        }
      });
  }, []);

  return (
    <Modal
      className={styles.modal}
      closable={false}
      destroyOnClose
      footer={
        <Row align="middle" gutter={[16, 16]} justify="space-between">
          <Col>
            <Space>
              <AcceptAllButton onClick={() => onClose?.()} />
              <DeclineAllButton onClick={() => onClose?.()} />
            </Space>
          </Col>
          <Col>
            <Button
              icon={<SaveOutlined />}
              onClick={() => {
                onClose?.();
                onSave();
              }}
              type="primary"
            >
              Save & Close
            </Button>
          </Col>
        </Row>
      }
      maskClosable={false}
      title={
        <Typography.Text strong>This website uses cookies</Typography.Text>
      }
      width={Breakpoint.MD_MIN}
      {...rest}
    >
      <CookieList
        className={styles.list}
        header={
          <>
            We use cookies to improve user experience. Choose what cookies you
            allow us to use. You can read more about our Cookie Policy in our{' '}
            <Typography.Link
              href="https://swaarm.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Typography.Link>
            .
          </>
        }
      />
    </Modal>
  );
};

CookieConsentModal.displayName = 'CookieConsentModal';

export default CookieConsentModal;
