import { Button, Space, Typography } from 'antd';
import type { ColumnGroupType, ColumnType } from 'antd/es/table';
import { HTMLAttributes, useMemo } from 'react';

import { formatInteger } from '../../../utils/format';
import { DataTableProps, RowSelectionMode } from '../index';

import styles from './styles.modules.less';

export type HeaderRowProps<RecordType> = Pick<
  DataTableProps<RecordType>,
  'rowSelection'
> &
  HTMLAttributes<HTMLTableRowElement> & {
    columns: readonly ColumnType<RecordType>[];
    total?: number;
    colspan: number;
  };

const HeaderRow = <RecordType,>({
  columns = [],
  rowSelection = {},
  total = 0,
  children,
  colspan,
  ...rest
}: HeaderRowProps<RecordType>): JSX.Element => {
  const isLastRow = useMemo(
    () =>
      !columns?.some(
        (column: ColumnGroupType<RecordType> | ColumnType<RecordType>) =>
          'children' in column,
      ),
    [columns],
  );

  const { selectedRowKeys, onSelectTotal, onSelectNone } = rowSelection;

  const mode = useMemo(() => {
    if (selectedRowKeys?.length === total) {
      return RowSelectionMode.All;
    }

    return rowSelection?.mode;
  }, [rowSelection?.mode, selectedRowKeys?.length, total]);

  if (!rowSelection || !total || !isLastRow) {
    return <tr {...rest}>{children}</tr>;
  }

  return (
    <>
      <tr {...rest}>{children}</tr>
      {selectedRowKeys &&
        (selectedRowKeys.length > 0 || mode === RowSelectionMode.All) && (
          <tr>
            <th className={styles.row} colSpan={colspan + 1}>
              <Space className={styles.wrapper}>
                <span>
                  {mode === RowSelectionMode.All ? (
                    <>
                      All{' '}
                      <Typography.Text strong>
                        {formatInteger(total)}
                      </Typography.Text>{' '}
                    </>
                  ) : (
                    <>
                      <Typography.Text strong>
                        {formatInteger(selectedRowKeys.length)}
                      </Typography.Text>{' '}
                      {total < Infinity && (
                        <>
                          of{' '}
                          <Typography.Text strong>
                            {formatInteger(total)}
                          </Typography.Text>{' '}
                        </>
                      )}
                    </>
                  )}
                  items are selected.
                </span>
                {onSelectTotal &&
                  total > selectedRowKeys.length &&
                  mode !== RowSelectionMode.All && (
                    <Button onClick={onSelectTotal} size="small" type="link">
                      Select All
                    </Button>
                  )}
                {onSelectNone && (
                  <Button onClick={onSelectNone} size="small" type="link">
                    Unselect All
                  </Button>
                )}
              </Space>
            </th>
          </tr>
        )}
    </>
  );
};

export default HeaderRow;
