import { List, ListProps } from 'antd';
import clsx from 'classnames';
import { FC } from 'react';

import { CookieCategory } from '../CookieSettingsProvider';
import cookies from '../CookieSettingsProvider/cookies.json';

import CookieSwitch from './CookieSwitch';
import CookieTable from './CookieTable';
import styles from './styles.module.less';

type CookieList = ListProps<CookieCategory>;

const CookieList: FC<CookieList> = ({ className, size, ...rest }) => {
  return (
    <List
      className={clsx(styles.list, className)}
      dataSource={cookies as CookieCategory[]}
      itemLayout="vertical"
      renderItem={({ name, cookies, ...item }) => (
        <List.Item
          extra={
            <CookieSwitch
              name={name}
              size={size === 'large' ? 'default' : size}
            />
          }
        >
          <List.Item.Meta {...item} />
          <CookieTable dataSource={cookies} />
        </List.Item>
      )}
      size={size}
      {...rest}
    />
  );
};

CookieList.displayName = 'CookieList';

export default CookieList;
