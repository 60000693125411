import { ApolloProvider } from '@apollo/client';
import { FC, PropsWithChildren } from 'react';

import useClient from './useClient';

const GraphQLProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const client = useClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphQLProvider.displayName = 'GraphQLProvider';

export default GraphQLProvider;
