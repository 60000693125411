import { SentryLink } from 'apollo-link-sentry';

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeError: true,
    includeQuery: true,
    includeVariables: true,
  },
});

export default sentryLink;
