import { parse, ParsedQs, stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useNamespace } from '../components/NamespaceProvider';

export const parseSearch = <T>(search: string) =>
  parse(search, {
    arrayLimit: Infinity, // qs otherwise parses array as an object if length > 20
    decoder(value, decoder) {
      const keywords: Record<string, false | true | null | undefined> = {
        false: false,
        null: null,
        true: true,
        undefined: undefined,
      };

      if (value in keywords) {
        return keywords[value];
      }

      try {
        return decoder(value);
      } catch (e) {
        return value;
      }
    },
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  }) as unknown as T;

export const useSearch = <T extends Record<string, unknown> = ParsedQs>({
  namespacing = true,
}: {
  namespacing?: boolean;
} = {}): T => {
  const namespace = useNamespace();
  const location = useLocation();
  const { search } = location;

  return useMemo(() => {
    const parsedSearch = parseSearch<T>(search);

    if (namespace && namespacing) {
      return (namespace in parsedSearch ? parsedSearch[namespace] : {}) as T;
    }

    return parsedSearch;
  }, [namespacing, namespace, search]);
};

export const useUpdateSearch = <T extends Record<string, unknown> = ParsedQs>({
  namespacing = true,
}: {
  namespacing?: boolean;
} = {}): ((query: Record<string, unknown>) => void) => {
  const history = useHistory();
  const namespace = useNamespace();
  const currentQuery = useSearch<T>();

  return useCallback(
    (query, { replace = false } = {}) => {
      let search;

      if (namespace && namespacing) {
        search = stringifyQuery({
          ...parseSearch(history.location.search),
          [namespace]: {
            ...currentQuery,
            ...query,
          },
        });
      } else {
        search = stringifyQuery({
          ...parseSearch(history.location.search),
          ...query,
        });
      }

      if (replace) {
        history.replace({
          ...location,
          search,
        });
      } else {
        history.push({
          ...location,
          search,
        });
      }
    },
    [currentQuery, history, namespace, namespacing],
  );
};

export const useSearchQuery = <T extends Record<string, unknown> = ParsedQs>({
  namespacing = true,
}: {
  namespacing?: boolean;
} = {}): {
  searchQuery: Partial<T>;
  updateSearchQuery: (query: Record<string, unknown>) => void;
} => {
  const searchQuery = useSearch<T>({ namespacing });

  const updateSearchQuery = useUpdateSearch<T>({ namespacing });

  return useMemo(
    () => ({
      searchQuery,
      updateSearchQuery,
    }),
    [searchQuery, updateSearchQuery],
  );
};

export const stringifyQuery = (query: Record<string, unknown>): string =>
  stringify(query, {
    addQueryPrefix: true,
    strictNullHandling: true,
  });
