import { StopTwoTone } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';

const Suspended: FC = () => (
  <div className={styles.suspended}>
    <Result
      extra={
        <Button href="mailto:support@swaarm.com" type="primary">
          Contact Support
        </Button>
      }
      icon={<StopTwoTone twoToneColor="#f5222d" />}
      subTitle="Your account has been suspended. Please contact support."
      title="Suspended Account"
    />
  </div>
);

Suspended.displayName = 'Suspended';

export default Suspended;
